import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { PiUsersFourFill } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";
import { MdReport } from "react-icons/md";
import { BiCategoryAlt } from "react-icons/bi";
import { SiBrandfolder } from "react-icons/si";
import { IoIosSettings } from "react-icons/io";
import { RiProductHuntLine } from "react-icons/ri";
import { FaBars, FaCarSide, FaBlog, FaFirstOrder } from "react-icons/fa";
import { FaCodePullRequest, FaUsersGear } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { authHandler } from "../../store/reducers";
import "./style.css";

export const Sidebar = ({ children }: { children: ReactNode }) => {
  const [toggle, setToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const dispatch = useDispatch();

  const logoutHandler = () => {
    const c = window.confirm("Are you sure to logout?");
    if (c) {
      sessionStorage.removeItem("ridingApp-admin");
      const payload: any = {
        isAuthenticated: false,
        token: null,
      };
      dispatch(authHandler(payload));
    }
  };

  return (
    <div className={`sidebar-container ${toggle && "toggled"}`}>
      <aside className="sidebar-wrapper">
        <div className="sidebar-brand">
          <h2>Oqab Rider</h2>
        </div>
        <ul className="sidebar-nav">
          <li
            className={activeTab.includes("dashboard") ? "active" : ""}
            onClick={() => setActiveTab("dashboard")}
          >
            <Link to="/dashboard">
              <AiFillDashboard />
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("users") ? "active" : ""}
            onClick={() => setActiveTab("users")}
          >
            <Link to="/users">
              <PiUsersFourFill />
              <span>Users</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("riders") ? "active" : ""}
            onClick={() => setActiveTab("riders")}
          >
            <Link to="/riders">
              <FaUsersGear />
              <span>Riders</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("veicles") ? "active" : ""}
            onClick={() => setActiveTab("veicles")}
          >
            <Link to="/vehicles">
              <FaCarSide />
              <span>Vehicles</span>
            </Link>
          </li>
          <li
            className={activeTab.includes("logout") ? "active" : ""}
            onClick={logoutHandler}
          >
            <a href="#">
              <FiLogOut />
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </aside>
      <div className="navbar-wrapper">
        <nav className="navbar navbar-inverse">
          <div className="container-fluid">
            <div className="navbar-header pointer">
              <span onClick={() => setToggle((p) => !p)}>
                <FaBars />
              </span>
            </div>
          </div>
        </nav>
      </div>

      <section className="content-wrapper">{children}</section>
    </div>
  );
};
