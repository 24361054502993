import { toast } from "react-toastify";
import { auth, firestore, storageRef } from "./config";

export const uploadFile = async (image) => {
  const uid = Date.now();
  const storageRefrence = storageRef.ref("/products").child("/" + uid + ".png");
  const uploadedFile = await storageRefrence.put(image);
  const imageUrl = await uploadedFile.ref.getDownloadURL();
  return imageUrl;
};

export const loginAdmin = async (data) => {
  const authRes = await auth.signInWithEmailAndPassword(
    data.email,
    data.password
  );
  const res = await firestore.collection("admin").doc(authRes.user.uid).get();
  if (!res.exists) {
    toast.error("Invalid Admin");
    return false;
  } else {
    const payload = { name: res.data().name, email: res.data().email };
    sessionStorage.setItem("ridingApp-admin", JSON.stringify(payload));
    return payload;
  }
};

export const approveRider = async (id, status) => {
  await firestore
    .collection("riderCollection")
    .doc(id)
    .update({ isApproved: status });
};
export const blockHandler = async (id, status) => {
  await firestore
    .collection("riderCollection")
    .doc(id)
    .update({ isBlocked: status });
};

export const getRiders = async (limit) => {
  const ref = firestore.collection("riderCollection");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};
export const getUsers = async (limit) => {
  const ref = firestore.collection("userCollection").limit(limit);
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const getDashboardData = async () => {
  const ref = firestore.collection("riderCollection");
  const res = await ref.get();
  const ref1 = firestore.collection("userCollection");
  const res1 = await ref1.get();
  return {
    riders: res.docs.map((item) => item.data()).length,
    users: res1.docs.map((item) => item.data()).length,
  };
};


export const getVehicles = async (limit) => {
  const ref = firestore.collection("vehicleCollection");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const addVehicle = async (data) => {
  const ref = firestore
    .collection("vehicleCollection").doc()
  await ref.set({ ...data, docID: ref.id })
};

export const updateVehicle = async (id, data) => {
  await firestore
    .collection("vehicleCollection")
    .doc(id)
    .update({ ...data });
};