import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDxaOr23IRzUvEr2bqTZ2oclfk4daiFAmY",
  authDomain: "riding-app-973ca.firebaseapp.com",
  projectId: "riding-app-973ca",
  storageBucket: "riding-app-973ca.appspot.com",
  messagingSenderId: "817643472922",
  appId: "1:817643472922:web:2eb887d31ebe56f26ee9a3",
  measurementId: "G-DGHWB0DPZR",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storageRef = firebase.storage();
