import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import useSWR from "swr";
import { Loader } from "../../components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { getUsers } from "../../Firebase/apis";
import { MdOutlineExpandMore } from "react-icons/md";

export const Customers = () => {
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [state, setState] = useState({
    id: "",
    name: null,
    image: null,
    email: null,
    phoneNumber: null,
  });
  const { data, mutate, error } = useSWR("/getCustomers", () =>
    getUsers(limit)
  );

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  if (error) {
    alert(error.message);
    return <></>;
  }
  if (!data) return <Loader />;
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <img
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                  src={
                    item.image && item.image.length
                      ? item.image
                      : "placeholder.jpg"
                  }
                  alt={item.name}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>

              <td>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setShow(true);
                    setState({
                      id: item.docID,
                      name: item.name,
                      image: item.image,
                      email: item.email,
                      phoneNumber: item.phoneNumber,
                    });
                  }}
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Container
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          setLimit((p) => p + 5);
          setTimeout(() => {
            mutate();
          }, 100);
        }}
      >
        <Button className="btn btn-primary">
          <MdOutlineExpandMore />
        </Button>
      </Container>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header>Customer Form</Modal.Header>
        <Modal.Body>
          <Row>
            <img
              width="100%"
              height="300px"
              alt={state.name ? state.name : ""}
              src={state.image ? state.image : "placeholder.jpg"}
            />
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control value={state.name ? state.name : ""} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control value={state.email ? state.email : ""} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={state.phoneNumber ? state.phoneNumber : ""}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
