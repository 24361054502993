import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import useSWR from "swr";
import { Input, Loader } from "..";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { approveRider, blockHandler, getRiders } from "../../Firebase/apis";
import { MdOutlineExpandMore } from "react-icons/md";

export const Riders = () => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState<any>([]);
  const [state, setState] = useState({
    id: "",
    name: "",
    email: "",
    phoneNumber: "",
    cnic: "",
    cnicFront: "",
    cnicBack: "",
    image: "",
    isBlocked: false,
    isApproved: false,
  });

  const validations = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string().required(),
    cnic: yup.string().required(),
    isBlocked: yup.boolean().required(),
    isApproved: yup.boolean().required(),
  });

  useEffect(() => {
    getRiders()
      .then((res: any) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }, []);

  const changeHandler = (key: string, value: string) => {
    setState((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const statusChangeHandler = async (event: any, id: string) => {
    const c = window.confirm(
      `Are you sure to ${event.target.checked ? "Enabled" : "Disabled"}?`
    );
    if (!c) return;
    try {
      setLoading(true);
      await blockHandler(id, event.target.checked);
      const copy = [...data];
      const index = copy.findIndex((item) => item.docID == id);
      if (index >= 0) {
        copy[index].isBlocked = event.target.checked;
      }
      setData(copy);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const verificationChangeHandler = async (e: any, id: any) => {
    const c = window.confirm(
      `Are you sure you want to ${e.target.checked ? "Verify" : "Un-Verify"}?`
    );
    if (!c) return;
    try {
      setLoading(true);
      await approveRider(id, e.target.checked);
      const copy = [...data];
      const index = copy.findIndex((item) => item.docID == id);
      if (index >= 0) {
        copy[index].isApproved = e.target.checked;
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {};

  if (loading) return <Loader />;
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Approve</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <img
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                  src={
                    item.image && item.image.length
                      ? item.image
                      : "placeholder.jpg"
                  }
                  alt={item.name}
                />
              </td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="customerVerificationStatus"
                    onChange={(e) => verificationChangeHandler(e, item.docID)}
                    checked={item.isApproved}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customerVerificationStatus"
                  >
                    Un-Approved/Aprroved
                  </label>
                </div>
              </td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="customerStatus"
                    onChange={(e) => statusChangeHandler(e, item.docID)}
                    checked={item.isBlocked}
                  />
                  <label className="form-check-label" htmlFor="customerStatus">
                    Enabled/Disabled
                  </label>
                </div>
              </td>
              <td>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setShow(true);
                    setState({
                      id: item.docID,
                      name: item.name,
                      email: item.email,
                      phoneNumber: item.phoneNumber,
                      cnic: item.cnic,
                      cnicFront: item.cnicFront,
                      cnicBack: item.cnicBack,
                      image: item.image,
                      isBlocked: item.isBlocked,
                      isApproved: item.isApproved,
                    });
                  }}
                >
                  View/Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Container
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          setLimit((p) => p + 5);
        }}
      >
        <Button className="btn btn-primary">
          <MdOutlineExpandMore />
        </Button>
      </Container> */}
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header>Rider Form</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={state}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Container className="mb-3">
                  <img
                    width={150}
                    height={150}
                    src={
                      state.image && state.image.length
                        ? state.image
                        : "placeholder.jpg"
                    }
                    alt={state.name}
                  />
                </Container>
                <Row>
                  <Col>
                    <Input
                      name="name"
                      label="Name"
                      value={state.name}
                      changeHandler={changeHandler}
                    />
                  </Col>
                  <Col>
                    <Input
                      name="email"
                      label="Email"
                      disabled={true}
                      value={state.email}
                      changeHandler={changeHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      name="phoneNumber"
                      label="Mobile Number"
                      value={state.phoneNumber}
                      changeHandler={changeHandler}
                    />
                  </Col>
                  <Col>
                    <Input
                      name="cnic"
                      disabled={true}
                      label="CNIC"
                      value={state.cnic}
                      changeHandler={changeHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label style={{ marginLeft: 10 }}>CNIC Front</label>
                    <img
                      width="100%"
                      height={150}
                      src={
                        state.cnicFront && state.cnicFront.length
                          ? state.cnicFront
                          : "placeholder.jpg"
                      }
                      alt="CNIC FRONT"
                    />
                  </Col>
                  <Col>
                    <label style={{ marginLeft: 10 }}>CNIC Back</label>
                    <img
                      width="100%"
                      height={150}
                      src={
                        state.cnicBack && state.cnicBack.length
                          ? state.cnicBack
                          : "placeholder.jpg"
                      }
                      alt="CNIC BACK"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
