import { Col, Container, Row } from "react-bootstrap";
import { Card, Loader } from "../components";
import { FaUserGraduate } from "react-icons/fa";
import { HiOutlineUsers } from "react-icons/hi";
import useSWR from "swr";
import { toast } from "react-toastify";
import { getDashboardData } from "../Firebase/apis";

export const Dashboard = () => {
  const { data, error } = useSWR("/getDashboardData", getDashboardData);
  if (error) {
    toast.error(error.message);
    return <></>;
  }

  if (!data) return <Loader />;
  return (
    <Container>
      <h3 className="mb-3">Dashboard</h3>
      <Row>
        <Col>
          <Card
            title="Customers"
            value={data.users}
            Icon={HiOutlineUsers}
            bgcolor="bg-green"
            txtcolor="text-green"
            borderColor="border-green"
          />
        </Col>
        <Col>
          <Card
            title="Riders"
            value={data.riders}
            Icon={FaUserGraduate}
            bgcolor="bg-blue"
            txtcolor="text-blue"
            borderColor="border-blue"
          />
        </Col>
      </Row>
    </Container>
  );
};
