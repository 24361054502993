import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Users, Dashboard, Login, AllRiders } from "../pages";
import { useSelector } from "react-redux";
import { State } from "../types";
import { ProtectedRoute, Sidebar } from "../components";
import { Vehicles } from "../pages/vehicles";

const Index = () => {
  const admin = useSelector((state: State) => state.admin);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Sidebar>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Dashboard />}
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Users />}
              />
            }
          />
          <Route
            path="/riders"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<AllRiders />}
              />
            }
          />
          <Route
            path="/vehicles"
            element={
              <ProtectedRoute
                isAuthenticated={admin.isAuthenticated}
                authenticationPath="/login"
                outlet={<Vehicles />}
              />
            }
          />
        </Routes>
      </Sidebar>
    </Router>
  );
};

export default Index;
