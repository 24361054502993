import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import { Input, Loader } from "../components";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { addVehicle, getVehicles, updateVehicle, uploadFile } from "../Firebase/apis";

export const Vehicles = () => {
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [mutate, setMutate] = useState(false);
	const [data, setData] = useState<any>([]);
	const [state, setState] = useState({
		docID: "",
		vehicleName: "",
		perKmPrice: 0,
		index: 0,
		icon: "",
		image: ""
	});

	const validations = yup.object().shape({
		vehicleName: yup.string().required(),
		perKmPrice: yup.number().min(1).required(),
		index: yup.number().min(1).required(),
	});

	useEffect(() => {
		if (mutate || !mutate) {
			getVehicles()
				.then((res: any) => {
					setData(res);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					toast.error(err.message);
				});
		}
	}, [mutate]);

	const changeHandler = (key: string, value: string) => {
		setState((p) => ({
			...p,
			[key]: value,
		}));
	};
	const clearForm = () => {
		setState({
			docID: "",
			vehicleName: "",
			perKmPrice: 0,
			index: 0,
			icon: "",
			image: ""
		});
	};
	const fileChangeHandler = async (file: any, key: string) => {
		if (file) {
			try {
				const res = await uploadFile(file);
				if (res) {
					setState(p => ({
						...p,
						[key]: res
					}))
				}
			} catch (error: any) {
				toast.error(error.message);
			}
		}
	};

	const handleSubmit = async (data: any) => {
		try {
			setLoading(true);
			if (state.docID.length) {
				await updateVehicle(state.docID, { ...data, icon: state.icon, image: state.image });
				toast.success("Updated successfully!");
			} else {
				await addVehicle({ ...data, icon: state.icon, image: state.image });
				toast.success("Added successfully!");
			}
			setShow(false);
			clearForm();
			setMutate(t => !t);
			setLoading(false);
		} catch (error: any) {
			toast.error(error.message);
		}
	};

	if (loading) return <Loader />;
	return (
		<Container>
			<Button onClick={() => {
				setShow(true);
				clearForm();
			}} className="btn btn-primary">Add</Button>
			<Table>
				<thead>
					<tr>
						<th>#</th>
						<th>Image</th>
						<th>Name</th>
						<th>Per KM Price</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{data.map((item: any, index: number) => (
						<tr>
							<td>{index + 1}</td>
							<td>
								<img
									width={50}
									height={50}
									style={{ borderRadius: "50%" }}
									src={
										item.image && item.image.length
											? item.image
											: "placeholder.jpg"
									}
									alt={item.name}
								/>
							</td>
							<td>{item.vehicleName}</td>
							<td>{item.perKmPrice}</td>
							<td>
								<Button
									className="btn btn-primary"
									onClick={() => {
										setShow(true);
										setState({
											docID: item.docID,
											vehicleName: item.vehicleName,
											perKmPrice: item.perKmPrice,
											icon: item.icon,
											image: item.image,
											index: item.index
										});
									}}
								>
									View/Edit
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{/* <Container
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          setLimit((p) => p + 5);
        }}
      >
        <Button className="btn btn-primary">
          <MdOutlineExpandMore />
        </Button>
      </Container> */}
			<Modal show={show} onHide={() => setShow(false)} size="lg">
				<Modal.Header>Vehicle Form</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={state}
						validationSchema={validations}
						onSubmit={handleSubmit}
					>
						{() => (
							<Form>
								<Row>
									<Col>
										<Container className="mb-3">
											<img
												width={300}
												height={250}
												src={
													state.image && state.image.length
														? state.image
														: "placeholder.jpg"
												}
												alt={state.vehicleName}
											/>
											<div className="mb-3">
												<label htmlFor="vehicleimage" className="form-label">Upload Vehicle Image</label>
												<input
													className="form-control"
													type="file"
													id="vehicleimage"
													onChange={(e) => fileChangeHandler(e.target?.files?.[0], "image")}
												/>
											</div>
										</Container>
									</Col>
									<Col>
										<Container className="mb-3">
											<img
												width={300}
												height={250}
												src={
													state.icon && state.icon.length
														? state.icon
														: "placeholder.jpg"
												}
												alt={state.vehicleName}
											/>
											<div className="mb-3">
												<label htmlFor="vehicleicon" className="form-label">Upload Vehicle Icon</label>
												<input
													className="form-control"
													type="file"
													id="vehicleicon"
													onChange={(e) => fileChangeHandler(e.target?.files?.[0], "icon")}
												/>
											</div>
										</Container>
									</Col>
								</Row>
								<Row>
									<Col>
										<Input
											name="vehicleName"
											label="Vehicle Name"
											value={state.vehicleName}
											changeHandler={changeHandler}
										/>
									</Col>
									<Col>
										<Input
											name="perKmPrice"
											type="number"
											label="Per Km Price"
											value={state.perKmPrice}
											changeHandler={changeHandler}
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<Input
											name="index"
											label="Index"
											type="number"
											value={state.index}
											changeHandler={changeHandler}
										/>
									</Col>
								</Row>
								<Button type="submit" className="btn btn-success">Submit</Button>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</Container>
	);
};
