import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Input } from "../components";
import { toast } from "react-toastify";
import { Admin } from "../types";
import { Button, Card, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { authHandler } from "../store/reducers";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../Firebase/apis";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [state] = useState<Admin>({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const validations = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(6).required(),
  });

  const handleSubmit = async (values: Admin) => {
    try {
      setLoading(true);
      const res = await loginAdmin(values);
      if (res) {
        const payload: any = {
          isAuthenticated: true,
          token: res.email,
        };
        dispatch(authHandler(payload));
        navigate("/dashboard");
        toast.success("Login successfully!");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  return (
    <Container className="login-container">
      <Card>
        <Card.Body>
          <Formik
            initialValues={state}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {() => (
              <>
                <Form>
                  <h3 className="mb-4">Admin Login</h3>
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                  />
                  <Input
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    type="password"
                  />
                  <Button type="submit" className="btn btn-primary mt-3 px-5">
                    {loading ? <Spinner animation="grow" /> : "Login"}
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};
